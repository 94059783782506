<template>
  <div class="project">
    <h2>立项说明</h2>
    <div class="content">
      <ul>
        <li>
          <div class="project_title">详情：</div>
          <div class="project_xinxi">
            <div class="project_itme">
              <div class="title">项目名称：</div>
              <div>{{ name }}</div>
            </div>
            <div class="project_itme">
              <div class="title">项目时间：</div>
              <div>{{ time }}</div>
            </div>
            <div class="project_itme">
              <div class="title">立 项 人：</div>
              <div>{{ project_pep }}</div>
            </div>
          </div>
        </li>
        <li>
          <div class="project_title">立项原因：</div>
          <p>{{ content }}</p>
        </li>
        <li>
          <div class="project_title">附件预览：</div>
          <div
            class="demo-image__preview"
            v-for="(url, index) in urls"
            :key="url"
          >
            <el-image
              style="width: 100px; height: 100px"
              :src="url"
              :preview-src-list="getSrcList(index)"
            />
          </div>
        </li>
        <div class="button">
          <el-button type="primary" @click="projectForm = true" class="edit"
            >编辑/修改</el-button
          >
        </div>
      </ul>
    </div>

    <el-dialog v-model="projectForm" title="修改" width="600px">
      <el-form :model="projectform" ref="projectform" label-width="100px">
        <el-form-item label="项目名称：" prop="name">
          <el-input v-model="projectform.name" />
        </el-form-item>
        <el-form-item label="立项原因：">
          <el-input v-model="projectform.content" type="textarea" />
        </el-form-item>
        <el-form-item label="立项时间：">
          <el-date-picker
            v-model="projectform.build_at"
            type="date"
            placeholder="Pick one or more dates"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="立项人：">
          <el-input v-model="projectform.builder" />
        </el-form-item>
        <el-form-item label="附件上传：">
          <el-upload
            name="image"
            class="upload-demo"
            :multiple="true"
            action="https://sasstemplate.dyyseo.com/api/uploadfiles"
            :on-success="handleEditUpImage"
            :before-upload="beforeImageUpload"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.bmp"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="fileListMax"
            :on-exceed="handleExceed"
            :on-change="handleEditChange"
            :file-list="fileList"
          >
            <el-icon><Plus /></el-icon>
            <template #tip>
              <div class="el-upload_tip">
                只能上传5张图片,且单张图片大小不能超过5MB
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item class="form_button">
          <el-button type="primary" @click="submit()">提交</el-button>
          <el-button @click="projectForm = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog v-model="dialogVisible" class="dialog_class">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>
<script>
import request from "/utils/request.js";
export default {
  name: "",
  data() {
    return {
      name: "",
      content: "",
      time: "",
      project_pep: "",
      projectForm: false,
      projectform: {
        name: "",
        content: "",
        builder: "",
        build_at: "",
        project_id: "",
        imgs: [],
      },
      fileListMax: 5,
      fileList: [],
      urls: [],
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    getSrcList(index) {
      return this.urls.slice(index).concat(this.urls.slice(0, index));
    },
    Project() {
      let _this = this;
      request
        .get("api/projectcontent", { project_id: _this.$route.query.id })
        .then((res) => {
          _this.fileList = [];
          _this.projectform = res.data;
          var length = _this.projectform.imgs.length;
          var i;
          for (i = 0; i <= length - 1; i++) {
            var imageUrl = _this.projectform.imgs[i];
            var emptyObj = new Object();
            emptyObj.url = imageUrl;
            _this.fileList.push(emptyObj);
          }
          _this.name = res.data.name;
          _this.content = res.data.content;
          _this.time = res.data.build_at;
          _this.project_pep = res.data.builder;
          _this.urls = res.data.imgs;
          _this.srcList = _this.urls;
        });
    },
    submit() {
      let _this = this;
      _this.projectform.project_id = _this.$route.query.id;
      request.post("api/modprojectcontent", _this.projectform).then((res) => {
        if (res.code == 200) {
          this.Project();
          _this.projectForm = false;
          _this.$message.success("立项内容编辑成功");
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    handlePictureCardPreview(uploadFile) {
      let _this = this;
      _this.dialogImageUrl = uploadFile.url;
      _this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      let _this = this;
      _this.projectform.imgs = [];
      for (let i = 0; i < fileList.length; i++) {
        _this.projectform.imgs.push(fileList[i].url);
      }
    },
    handleEditChange(file, fileList) {
      let _this = this;
    },
    xianzi(file) {
      let _this = this;
      const isLimit = file.size / 1024 / 1024 <= 5;
      if (
        ["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(
          file.type
        ) === -1
      ) {
        _this.$message.error("上传图片只能是jpg/jpeg/gif/png格式");
        return false;
      }
      if (!isLimit) {
        _this.$message.error("上传图片大小不能超过" + 5 + "MB");
        return false;
      }
    },
    beforeImageUpload(file) {
      return this.xianzi(file);
    },
    handleEditUpImage(response) {
      let _this = this;
      if (response.success) {
        _this.projectform.imgs.push(response.files);
      }
    },
    handleExceed(files, fileList) {
      if (files.length > this.fileListMax) {
        this.$message.error("最多上传五张图片");
      }
    },
  },
  created() {
    this.Project();
  },
};
</script>
<style lang="less" scoped>
.project {
  margin: -20px;
  background: #f0f2f5;
  height: calc(100vh - 50px);
  h2 {
    font-size: 25px;
    padding: 15px 20px 15px 30px;
    margin: 0;
    background: #fff;
  }
  .content {
    padding: 20px;
    ul {
      li {
        background: #fff;
        // display: flex;
        list-style: none;
        border-radius: 4px;
        margin-bottom: 20px;
        .project_title {
          font-size: 18px;
          font-weight: bold;
          padding: 15px 20px;
          border-bottom: 1px solid #d9d9d9;
        }
        .project_xinxi {
          display: flex;
          align-items: center;
          padding: 20px;
          .project_itme {
            display: flex;
            width: 20%;
            font-size: 18px;
          }
        }
        p {
          padding: 20px;
          word-break: break-word;
          font-size: 16px;
          white-space: pre-line;
        }
      }
    }
  }
  .demo-image__preview {
    padding: 20px;
    border-radius: 6px;
    display: inline-block;
  }
  .edit {
    margin: 0 auto;
    display: block;
    padding: 0px 30px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
