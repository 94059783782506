<template>
  <div class="softness">
    <h2>软著信息</h2>
    <div class="softness_box">
      <el-button type="primary" @click="add()" class="add">
        上传信息
      </el-button>
      <el-table
        :data="softnessData"
        border
        style="width: 600px; margin: 0 auto 30px auto"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="name" label="软著名称" width="200" />
        <el-table-column prop="created_at" label="上传时间" width="200" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="danger" @click="dele(scope.row.id)"
              >删除</el-button
            >
            <el-button type="primary" size="small" @click="Eidt(scope.row.id)"
              >修改</el-button
            >
            <el-button type="info" size="small" @click="veiw(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          background
          :current-page="page"
          :page-sizes="[5, 10, 15]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <el-dialog v-model="softnessAddForm" title="软著添加" width="600px">
        <el-form :model="softnessForm" ref="softnessForm" label-width="100px">
          <el-form-item label="软著名称：" prop="name">
            <el-input v-model="softnessForm.name"></el-input>
          </el-form-item>
          <el-form-item label="附件上传：">
            <el-upload
              name="image"
              class="upload-demo"
              :multiple="true"
              action="https://sasstemplate.dyyseo.com/api/uploadfiles"
              :on-success="handleUpImage"
              :before-upload="beforeImageUpload"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.bmp"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleAddRemove"
              :on-exceed="handleExceed"
              :limit="fileListMax"
              :on-change="handleEditChange"
              :file-list="fileList"
            >
              <el-icon><Plus /></el-icon>
              <template #tip>
                <div class="el-upload_tip">
                  只能上传图片,且单张图片大小不能超过5MB
                </div>
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item class="form_button">
            <el-button type="primary" @click="addSubmit()">提交</el-button>
            <el-button @click="softnessAddForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog v-model="softnessEditForm" title="软著修改" width="600px">
        <el-form
          :model="softnessEdit_Form"
          ref="softnessForm"
          label-width="100px"
        >
          <el-form-item label="软著名称：" prop="name">
            <el-input v-model="softnessEdit_Form.name"></el-input>
          </el-form-item>
          <el-form-item label="附件上传：">
            <el-upload
              name="image"
              class="upload-demo"
              :multiple="true"
              action="https://sasstemplate.dyyseo.com/api/uploadfiles"
              :on-success="handleEditUpImage"
              :before-upload="beforeImageUpload"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.bmp"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleEditRemove"
              :on-exceed="handleExceed"
              :limit="fileListMax"
              :on-change="handleEditChange"
              :file-list="fileList"
            >
              <el-icon><Plus /></el-icon>
              <template #tip>
                <div class="el-upload_tip">
                  只能上传图片,且单张图片大小不能超过5MB
                </div>
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item class="form_button">
            <el-button type="primary" @click="editSubmit()">提交</el-button>
            <el-button @click="softnessEditForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog v-model="softnessViewForm" title="详情" width="600px">
        <el-form :model="softnessView_Form" label-width="100px">
          <el-form-item label="软著名称：" prop="name">
            <el-input v-model="softnessView_Form.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="附件预览：">
            <div class="form_imgs">
              <div
                class="demo-image__preview"
                v-for="(url, index) in urls"
                :key="url"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="url"
                  :preview-src-list="getSrcList(index)"
                />
              </div>
            </div>
          </el-form-item>

          <el-form-item class="form_button">
            <el-button @click="softnessViewForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog v-model="dialogVisible" class="dialog_class">
        <img w-full :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import request from "/utils/request.js";
export default {
  name: "softness",
  data() {
    return {
      total: null,
      pagination: {
        page: 1,
        pagesize: 10,
        project_id: "",
      },
      softnessData: [],
      softnessAddForm: false,
      softnessForm: {
        name: "",
        imgs: [],
        project_id: "",
      },
      fileListMax: 5,
      dialogVisible: false,
      dialogImageUrl: "",
      softnessEditForm: false,
      softnessEdit_Form: {},
      softnessViewForm: false,
      softnessView_Form: {},
      fileList: [],
      urls: [],
    };
  },

  created() {
    this.renew();
  },
  mounted() {},

  methods: {
    handleSizeChange(val) {
      this.pagination.pagesize = val;
      this.renew();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.renew();
    },
    // 添加软著
    add() {
      let _this = this;
      this.fileList = [];
      _this.softnessAddForm = true;
    },
    // 提交softnessForm表单
    addSubmit() {
      let _this = this;

      _this.softnessForm.project_id = _this.$route.query.id;
      request.post("api/addsne", _this.softnessForm).then((res) => {
        if (res.code == 200) {
          _this.softnessAddForm = false;
          _this.renew();
          _this.$message.success("软著信息添加成功");
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    // 点击修改
    Eidt(id) {
      let _this = this;
      request.get("api/snedetail", { id: id }).then((res) => {
        _this.fileList = [];
        _this.softnessEdit_Form = res.sne;
        var length = _this.softnessEdit_Form.imgs.length;
        var i;
        for (i = 0; i <= length - 1; i++) {
          var imageUrl = _this.softnessEdit_Form.imgs[i];
          var emptyObj = new Object();
          emptyObj.url = imageUrl;
          _this.fileList.push(emptyObj);
        }

        _this.softnessEditForm = true;
      });
    },
    // 提交修改
    editSubmit() {
      let _this = this;
      request.post("api/modsne", _this.softnessEdit_Form).then((res) => {
        if (res.code == 200) {
          _this.$message.success("信息修改成功");
          _this.softnessEditForm = false;
          _this.renew();
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    getSrcList(index) {
      return this.urls.slice(index).concat(this.urls.slice(0, index));
    },
    // 查看
    veiw(id) {
      let _this = this;
      request.get("api/snedetail", { id: id }).then((res) => {
        _this.softnessView_Form = res.sne;
        _this.urls = res.sne.imgs;
        // _this.srcList = _this.urls;
        _this.softnessViewForm = true;
      });
    },
    // 删除
    dele(id) {
      let _this = this;
      _this
        .$confirm("此操作将删除该信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          request.post("api/delsne", { id: id }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              _this.$message.success(res.msg);
              _this.renew();
            } else {
              _this.$message.error(res.msg);
            }
          });
        });
    },

    // 进入页面刷新数据
    renew() {
      let _this = this;
      request
        .get("api/snes", { project_id: _this.$route.query.id })
        .then((res) => {
          _this.softnessData = res.snes.data;
          _this.total = res.snes.total;
        });
    },

    // 附件提交
    handleUpImage(response) {
      let _this = this;
      if (response.success) {
        _this.softnessForm.imgs.push(response.files);
      }
    },
    handleEditUpImage(response) {
      let _this = this;
      if (response.success) {
        _this.softnessEdit_Form.imgs.push(response.files);
      }
    },
    // 附件限制
    xianzi(file) {
      let _this = this;
      const isLimit = file.size / 1024 / 1024 <= 5;
      if (
        ["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(
          file.type
        ) === -1
      ) {
        _this.$message.error("上传图片只能是jpg/jpeg/gif/png格式");
        return false;
      }
      if (!isLimit) {
        _this.$message.error("上传图片大小不能超过" + 5 + "MB");
        return false;
      }
    },
    // 附件删除
    handleAddRemove(file, fileList) {
      let _this = this;
      _this.softnessForm.imgs = [];
      for (let i = 0; i < fileList.length; i++) {
        _this.softnessForm.imgs.push(fileList[i].url);
      }
    },
    handleEditRemove(file, fileList) {
      let _this = this;
      _this.softnessEdit_Form.imgs = [];
      for (let i = 0; i < fileList.length; i++) {
        _this.softnessEdit_Form.imgs.push(fileList[i].url);
      }
    },
    // 图片数量改变
    handleEditChange(file, fileList) {
      let _this = this;
    },
    handleExceed(files, fileList) {
      if (files.length > this.fileListMax) {
        this.$message.error("最多上传五张图片");
      }
    },
    // 执行附件限制
    beforeImageUpload(file) {
      return this.xianzi(file);
    },
    // 点击查看图片
    handlePictureCardPreview(uploadFile) {
      let _this = this;
      _this.dialogImageUrl = uploadFile.url;
      _this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.softness {
  h2 {
    text-align: center;
    font-size: 35px;
    margin-bottom: 30px;
  }
  .softness_box {
    width: 600px;
    margin: 0 auto;
    .add {
      margin-bottom: 30px;
    }
  }
  .form_imgs {
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .demo-image__preview {
      margin-right: 10px;
      border: 1px solid #dcdfe6;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
