<template>
  <div class="itme">
    <div class="itme_head">
      <el-form :model="search_form" ref="search_form">
        <el-form-item label="项目搜索" label-width="68px">
          <el-input
            v-model="search_form.name"
            placeholder="请输入内容"
            class="search_input"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="日期搜索" label-width="68px">
          <el-date-picker
            v-model="search_form.start_at"
            type="date"
            placeholder="开始时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
          -
          <el-date-picker
            v-model="search_form.stop_at"
            type="date"
            placeholder="结束时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="性质" label-width="48px">
          <el-select
            v-model="search_form.type"
            clearable
            placeholder="项目性质"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()">
            <el-icon><Search /></el-icon>搜索</el-button
          >
          <el-button @click="searchReset('form')"
            ><el-icon><Refresh /></el-icon>重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-button type="primary" plain @click="dialogForm = true" class="add">
        <el-icon><Plus /></el-icon>
        项目新增
      </el-button>
    </div>
    <el-table
      :data="itmeData"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="name" label="项目名称" width="280">
        <template #default="scope">
          <router-link :to="{ name: 'project', query: { id: scope.row.id } }">
            {{ scope.row.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="start_at" label="开始时间" width="120" />
      <el-table-column prop="stop_at" label="结束时间" width="120" />
      <el-table-column prop="members" label="项目成员" width="500" />

      <el-table-column prop="type" label="性质" width="120">
        <template #default="scope">
          <el-tag v-if="scope.row.type == 1">内部工具</el-tag>
          <el-tag v-else>服务工具</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="200" fixed="right">
        <template #default="scope">
          <el-button size="small" type="danger" @click="del(scope.row.id)"
            >删除</el-button
          >
          <el-button type="primary" size="small" @click="show(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="info"
            size="small"
            @click="
              this.$router.push({
                path: '/project',
                query: { id: scope.row.id },
              })
            "
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        background
        :current-page="page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog v-model="dialogForm" title="项目新增" width="600px">
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item label="项目名称：" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="项目成员：" class="padding_left">
          <el-input v-model="form.members" type="textarea" />
        </el-form-item>
        <el-form-item label="开始时间：" prop="start_at">
          <el-date-picker
            v-model="form.start_at"
            type="date"
            placeholder="开始时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="结束时间：" class="padding_left">
          <el-date-picker
            v-model="form.stop_at"
            type="date"
            placeholder="结束时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="性质：" class="padding_left">
          <el-select v-model="form.type" clearable placeholder="项目性质">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item class="form_button">
          <el-button type="primary" @click="onSubmit()">提交</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog v-model="showForm" title="项目修改" width="600px">
      <el-form
        :model="show_form"
        ref="show_form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="项目名称：" prop="name">
          <el-input v-model="show_form.name" />
        </el-form-item>
        <el-form-item label="项目成员：">
          <el-input v-model="show_form.members" type="textarea" />
        </el-form-item>
        <el-form-item label="开始时间：" prop="start_at">
          <el-date-picker
            v-model="show_form.start_at"
            type="date"
            placeholder="开始时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker
            v-model="show_form.stop_at"
            type="date"
            placeholder="结束时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="性质：" class="padding_left">
          <el-select v-model="show_form.type" clearable placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="form_button">
          <el-button type="primary" @click="showSubmit()">提交</el-button>
          <el-button @click="showForm = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import request from "/utils/request.js";
export default {
  data() {
    return {
      showForm: false,
      search_form: {
        name: "",
        start_at: "",
        stop_at: "",
        type: "",
        page: 1,
        pagesize: 10,
      },
      total: null,
      pageshow: true,
      value: "",
      options: [
        {
          value: 2,
          label: "服务工具",
        },
        {
          value: 1,
          label: "内部工具",
        },
      ],
      dialogForm: false,
      form: {
        name: "",
        type: "",
        members: "",
        start_at: "",
        stop_at: "",
      },
      show_form: {},
      rules: {
        name: [{ required: true, message: "请填写项目名称", trigger: "blur" }],
        start_at: [
          { required: true, message: "请填写开始时间", trigger: "blur" },
        ],
      },
      itmeData: [],
    };
  },
  created() {
    this.loadAdmin();
  },

  methods: {
    // 查询
    search() {
      let _this = this;
      if (
        _this.search_form.name !== "" ||
        _this.search_form.start_at !== "" ||
        _this.search_form.stop_at !== "" ||
        _this.search_form.type !== ""
      ) {
        request.get("api/projects", this.search_form).then((res) => {
          this.itmeData = res.data;
          this.total = res.total;
        });
      } else {
        this.loadAdmin();
      }
    },
    // 查询重置
    searchReset() {
      let _this = this;
      if (_this.$refs.search_form !== undefined)
        _this.$refs.search_form.resetFields();
      this.$data.search_form = JSON.parse(
        JSON.stringify(this.$options.data().form)
      );
    },
    // 删除项目
    del(id) {
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request.post("api/delproject", { id: id }).then((res) => {
          if (res.code == 200) {
            this.$message.error("删除项目成功！");
          } else {
            this.$message.success("删除项目失败！");
          }
          this.loadAdmin();
        });
      });
    },
    // 点击项目
    show(id) {
      request.get("api/projectdetail", { id: id }).then((res) => {
        this.show_form = res.project;
        this.showForm = true;
      });
    },
    // 发送修改信息
    showSubmit() {
      this.$refs.show_form.validate((valid) => {
        if (valid) {
          request.post("api/modproject", this.show_form).then((res) => {
            if (res.code == 200) {
              // 隐藏添加用户对话框
              this.showForm = false;
              this.$message.success("项目信息修改成功！");
              this.loadAdmin();
            } else {
              this.$message.error("项目修改失败！");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 发送添加项目
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          request.post("api/addproject", this.form).then((res) => {
            if (res.code == 200) {
              // 隐藏添加用户对话框
              this.dialogForm = false;
              this.$message.success("更新项目信息成功！");
              this.loadAdmin();
              // this.resetForm();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 重置添加表单
    resetForm() {
      let _this = this;
      if (_this.$refs.form !== undefined) _this.$refs.form.resetFields();
      this.$data.form = JSON.parse(JSON.stringify(this.$options.data().form));
    },
    // 进去页面获取列表数据
    loadAdmin() {
      request.get("api/projects", this.search_form).then((res) => {
        this.itmeData = res.data;
        this.total = res.total;
      });
    },
    // 页码改变时触发
    handleSizeChange(val) {
      this.search_form.pagesize = val;
      this.loadAdmin();
    },
    // 分页切换时触发
    handleCurrentChange(val) {
      this.search_form.page = val;
      this.loadAdmin();
    },
  },
};
</script>
<style lang="less" scoped>
.itme {
  .itme_head {
    margin-bottom: 20px;
    .date-picker {
      display: inline-block;
      margin-right: 20px;
    }
    .search_input {
      display: inline-block;
      width: 200px;
    }
    .el-form {
      .el-form-item {
        display: inline-block;
        margin-right: 18px;
        .el-form-item__label {
          display: inline-block;
        }
      }
      .el-icon {
        margin-right: 5px;
      }
      .button {
        display: inline-block;
      }
    }
    .el-form-item__content {
      display: inline-block;
    }
  }
  .demo-pagination-block {
    margin-top: 30px;
  }
}
</style>
