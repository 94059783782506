<template>
  <div class="report">
    <div class="el-directory">
      <div class="title">目录</div>
      <ul v-for="(item, index) in directory" :key="index" class="directory_one">
        <li>
          <span @click="contentTabs(item)">{{ item.name }}</span>
          <ul
            v-for="(itemone, cindex) in item.children"
            :key="cindex"
            class="directory_two"
          >
            <li>
              <span @click="contentTabs(itemone)">{{ itemone.name }}</span>
              <ul
                v-for="(itemtwo, tindex) in itemone.children"
                :key="tindex"
                class="directory_three"
              >
                <li>
                  <span @click="contentTabs(itemtwo)">{{ itemtwo.name }}</span>
                  <ul
                    v-for="(itemthree, sindex) in itemtwo.children"
                    :key="sindex"
                    class="directory_four"
                  >
                    <li>
                      <span @click="contentTabs(itemthree)">{{
                        itemthree.name
                      }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="el-content">
      <div v-for="(item, index) in titles" :key="index" class="title">
        <div v-show="name == item.key" class="h1">
          {{ item.name }}
        </div>
      </div>
      <div class="content_title">{{ contentName }}</div>
      <div>
        <div id="summernote2"></div>
        <el-dialog v-model="dialogContentForm" title="修改内容" width="1000px">
          <el-form :model="dialogcontentform" ref="">
            <div id="summernote"></div>
            <el-form-item>
              <el-button type="primary" @click="contentSubmit()"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-button type="primary" @click="bianji()" class="bianji"
          >编辑</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import request from "/utils/request.js";
import $ from "jquery";
export default {
  name: "index",
  data() {
    return {
      content: "",
      val: "",
      name: "",
      contentName: "",
      block: true,
      directory: [],
      dialogContentForm: false,
      dialogcontentform: {
        id: "",
        content: "",
      },
      yiwei: [],
      titles: [
        {
          key: "plan",
          name: "研发计划书",
        },
        {
          key: "trial",
          name: "研发功能测试报告",
        },
        {
          key: "result",
          name: "阶段性成果报告",
        },
        {
          key: "overview",
          name: "项目验收总结报告",
        },
      ],
    };
  },
  components: {
    // Simditor,
  },
  created() {
    this.shuaxin(this.$route.query.name);
    this.name = this.$route.query.name;
  },
  mounted() {
    this.summernoteTwo();
  },
  watch: {
    $route(to, from) {
      this.shuaxin(this.$route.query.name);
      this.name = this.$route.query.name;
    },
  },
  methods: {
    // 点击目录内容切换
    contentTabs(index) {
      let _this = this;
      if (!index.children) {
        request.get("api/reportdetail", { id: index.id }).then((res) => {
          _this.block = false;
          _this.contentName = res.report.name;
          _this.dialogcontentform.id = res.report.id;
          _this.content = res.report.content;
          $("#summernote2").summernote("code", _this.content);
        });
      } else {
        ElMessage({
          message: "请点击该目录的子目录显示内容",
          type: "warning",
        });
      }
    },
    bianji() {
      let _this = this;
      _this.dialogContentForm = true;
      setTimeout(() => {
        _this.summernote();
        $("#summernote").summernote("code", _this.content);
      }, 10);
    },
    // 编辑内容
    contentSubmit() {
      let _this = this;
      console.log(_this.content);
      _this.dialogcontentform.content = _this.content;
      request
        .post("api/modifyreportcontent", _this.dialogcontentform)
        .then((res) => {
          if (res.code == 200) {
            // 隐藏添加用户对话框
            this.$message.success("文档编辑成功");
            _this.dialogContentForm = false;
            $("#summernote2").summernote("code", _this.content);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 引用summernote
    summernote() {
      var that = this;
      $("#summernote").summernote({
        // 设置高度
        height: 300,
        // 设置语言
        lang: "zh-CN",
        // 禁止拖拽
        disableDragAndDrop: true,
        // 工具栏
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "italic", "underline", "strikethrough", "clear"]],
          ["color", ["color"]],
          ["para", ["paragraph"]],
          ["table", ["table"]],
          ["insert", ["picture"]],
          ["view", ["undo", "fullscreen", "codeview"]],
        ],
        // 将富文本编辑器中输入的内容保存到data数据中
        callbacks: {
          onChange: function (contents, $editable) {
            // contents: 输入的内容
            that.content = contents;
          },
          // 上传图片到服务器并在编辑器中显示出来
          onImageUpload: function (files) {
            // 上传图片到服务器，使用了formData对象，至于兼容性...据说对低版本IE不太友好
            let multiForm = new FormData();
            for (var i = 0; i < files.length; i++) {
              multiForm.append("img", files[i]);
              $.ajax({
                url: "https://sasstemplate.dyyseo.com/api/uploadfile", // 填写后台文件上传接口
                type: "post",
                data: multiForm,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (res) {
                  var image = `<img src="` + `${res.msg}` + `">	`;
                  $("#summernote").summernote("pasteHTML", image);
                },
              });
            }
          },
        },
      });
      $("#summernote").summernote({});
    },
    summernoteTwo() {
      $("#summernote2").summernote({
        // 设置高度
        height: 300,
        // 设置语言
        lang: "zh-CN",
        // 禁止拖拽
        disableDragAndDrop: true,
        // 工具栏
        toolbar: [],
        // 将富文本编辑器中输入的内容保存到data数据中
      });
      $("#summernote2").summernote("disable");
    },
    change(val) {
      // console.log(val); //以html格式获取simditor的正文内容
      this.content = val;
      val = this.val;
    },
    shuaxin(name) {
      let _this = this;
      request
        .get("api/reports", {
          project_id: _this.$route.query.id,
          field: name,
        })
        .then((res) => {
          _this.directory = res.data;
          _this.xuran();
        });
    },
    xuran() {
      var item = this.lopp(this.directory);
      this.contentTabs(item);
    },
    lopp(arr) {
      var result = null;
      if (arr != undefined) {
        for (let i = 0; i < arr.length; i++) {
          let a = arr[i];
          if (a.children == undefined) {
            result = a;
            break;
          } else {
            result = this.lopp(a.children);
            if (result != null) {
              break;
            }
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.report {
  display: flex;
  width: 100%;
  .el-directory {
    min-width: 300px;
    height: calc(100vh - 150px);
    border-radius: 4px;
    border: 1px solid #e6ebf5;
    background-color: #fff;
    overflow-y: auto;
    color: #303133;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    .title {
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center;
    }
    .modify_record {
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      padding-left: 10px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .modify_record:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 30px;
      background: #409eff;
    }
    .directory_one {
      list-style: none;
      > li {
        list-style: none;
        cursor: pointer;
        line-height: 25px;
        .directory_two {
          margin-left: 10px;
          > li {
            display: inline-block;
          }
          .directory_three {
            margin-left: 10px;
            > li {
              display: inline-block;
            }
            .directory_four {
              margin-left: 10px;
              > li {
                display: inline-block;
              }
            }
          }
        }
      }
      span {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  .el-content {
    padding: 0 20px;
    width: 100%;

    .title {
      text-align: left;
      display: block;
      .h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .content_title {
      position: relative;
      line-height: 30px;
      font-size: 20px;
      padding-left: 20px;
      display: block;
      height: 30px;
      margin-bottom: 30px;
    }
    .content_title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 4px;
      background: #409eff;
    }
    .content_text {
      margin-top: 30px;
    }
    .simditor {
      margin: 30px auto 0 auto;
    }
    .simditor .simditor-body img {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
    .bianji {
      margin: 30px auto 0 auto;
      border: none;
      padding: 10px 40px;
      height: 50px;
      border-radius: 8px;
      line-height: 35px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      display: block;
    }
  }
}
.simditor .simditor-body {
  min-height: 150px !important;
}
.simditor {
  border: 1px solid #eeeeee !important;
}
</style>
>
