<template>
  <div class="report">
    <div class="el-directory">
      <div class="title">目录</div>
      <div class="modify_record" @click="record()" v-if="xianshi">
        文件修改记录
      </div>
      <ul v-for="(item, index) in directory" :key="index" class="directory_one">
        <li>
          <span @click="contentTabs(item)">{{ item.name }}</span>
          <span @click="add(item)"
            ><el-icon><Plus /> </el-icon>
          </span>
          <span @click="delet(item)"
            ><el-icon><Delete /></el-icon
          ></span>
          <span @click="edit(item)"
            ><el-icon><EditPen /></el-icon
          ></span>
          <ul
            v-for="(itemone, cindex) in item.children"
            :key="cindex"
            class="directory_two"
          >
            <li>
              <span @click="contentTabs(itemone)"> {{ itemone.name }}</span>
              <span @click="add(itemone)"
                ><el-icon><Plus /> </el-icon>
              </span>
              <span @click="delet(itemone)"
                ><el-icon><Delete /></el-icon
              ></span>
              <span @click="edit(itemone)"
                ><el-icon><EditPen /></el-icon
              ></span>
              <ul
                v-for="(itemtwo, tindex) in itemone.children"
                :key="tindex"
                class="directory_three"
              >
                <li>
                  <span @click="contentTabs(itemtwo)">{{ itemtwo.name }}</span>
                  <span @click="add(itemtwo)"
                    ><el-icon><Plus /> </el-icon>
                  </span>
                  <span @click="delet(itemtwo)"
                    ><el-icon><Delete /></el-icon
                  ></span>
                  <span @click="edit(itemtwo)"
                    ><el-icon><EditPen /></el-icon
                  ></span>
                  <ul
                    v-for="(itemthree, sindex) in itemtwo.children"
                    :key="sindex"
                    class="directory_four"
                  >
                    <li>
                      <span @click="contentTabs(itemthree)">{{
                        itemthree.name
                      }}</span>
                      <span @click="delet(itemthree)"
                        ><el-icon><Delete /></el-icon
                      ></span>
                      <span @click="edit(itemthree)"
                        ><el-icon><EditPen /></el-icon
                      ></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <el-dialog v-model="directoryAddForm" title="添加目录" width="600px">
        <el-form
          :model="directoryaddform"
          ref="directoryaddform"
          label-width="100px"
        >
          <el-form-item label="标题名称：" prop="name">
            <el-input v-model="directoryaddform.name" />
          </el-form-item>
          <el-form-item class="form_button">
            <el-button type="primary" @click="addSubmit()">提交</el-button>
            <el-button @click="directoryAddForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog v-model="directoryEditForm" title="修改目录" width="600px">
        <el-form
          :model="directoryeditform"
          ref="directoryeditform"
          label-width="100px"
        >
          <el-form-item label="标题名称：" prop="name">
            <el-input v-model="directoryeditform.name" />
          </el-form-item>
          <el-form-item class="form_button">
            <el-button type="primary" @click="editSubmit()">提交</el-button>
            <el-button @click="directoryEditForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="el-content">
      <div v-for="(item, index) in titles" :key="index" class="title">
        <div v-show="name == item.key" class="h1">
          {{ item.name }}
        </div>
      </div>
      <div class="content_title">{{ contentName }}</div>
      <div v-if="block">
        <el-button
          type="primary"
          plain
          @click="recordAddForm = true"
          class="add"
        >
          <el-icon><Plus /></el-icon>
          记录添加
        </el-button>
        <el-table
          :data="recordData"
          border
          style="width: 100%; margin: 20px 0"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="draft_at" label="制定日期" width="120" />
          <el-table-column prop="effective_at" label="生效日期" width="120" />
          <el-table-column
            prop="content"
            label="制定 / 修订 内容摘要"
            width="300"
          />
          <el-table-column prop="version" label="版本" width="60" />
          <el-table-column prop="draft" label="拟稿" width="120" />
          <el-table-column prop="check" label="审查" width="120" />
          <el-table-column prop="approve" label="批准" width="120" />
          <el-table-column label="操作" min-width="150" fixed="right">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                @click="recordDel(scope.row.id)"
                >删除</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="recordEdit(scope.row.id)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="demo-pagination-block">
          <el-pagination
            background
            :current-page="page"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            layout="total,sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-dialog v-model="recordAddForm" title="记录添加" width="600px">
          <el-form :model="addform" ref="addform" label-width="100px">
            <el-form-item label="制定日期：" prop="name">
              <el-date-picker
                v-model="addform.draft_at"
                type="date"
                placeholder=""
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :size="size"
              />
            </el-form-item>
            <el-form-item label="生效日期：" prop="name">
              <el-date-picker
                v-model="addform.effective_at"
                type="date"
                placeholder=""
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :size="size"
              />
            </el-form-item>
            <el-form-item label="内容摘要：" prop="name">
              <el-input type="textarea" v-model="addform.content" />
            </el-form-item>
            <el-form-item label="版 本：" prop="name">
              <el-input v-model="addform.version" />
            </el-form-item>
            <el-form-item label="拟 稿：" prop="name">
              <el-input v-model="addform.draft" />
            </el-form-item>
            <el-form-item label="审 查：" prop="name">
              <el-input v-model="addform.check" />
            </el-form-item>
            <el-form-item label="批 准：" prop="name">
              <el-input v-model="addform.approve" />
            </el-form-item>
            <el-form-item class="form_button">
              <el-button type="primary" @click="recordSubmit()">提交</el-button>
              <el-button @click="resetForm('addform')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-dialog v-model="recordEditForm" title="记录修改" width="600px">
          <el-form
            :model="recordeditform"
            ref="recordeditform"
            label-width="100px"
          >
            <el-form-item label="制定日期：" prop="name">
              <el-date-picker
                v-model="recordeditform.draft_at"
                type="date"
                placeholder=""
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :size="size"
              />
            </el-form-item>
            <el-form-item label="生效日期：" prop="name">
              <el-date-picker
                v-model="recordeditform.effective_at"
                type="date"
                placeholder=""
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :size="size"
              />
            </el-form-item>
            <el-form-item label="内容摘要：" prop="name">
              <el-input type="textarea" v-model="recordeditform.content" />
            </el-form-item>
            <el-form-item label="版 本：" prop="name">
              <el-input v-model="recordeditform.version" />
            </el-form-item>
            <el-form-item label="拟 稿：" prop="name">
              <el-input v-model="recordeditform.draft" />
            </el-form-item>
            <el-form-item label="审 查：" prop="name">
              <el-input v-model="recordeditform.check" />
            </el-form-item>
            <el-form-item label="批 准：" prop="name">
              <el-input v-model="recordeditform.approve" />
            </el-form-item>
            <el-form-item class="form_button">
              <el-button type="primary" @click="recordEditSubmit()"
                >提交</el-button
              >
              <el-button @click="recordEditForm = false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
      <div v-else>
        <div id="summernote2"></div>

        <el-dialog v-model="dialogContentForm" title="修改内容" width="1000px">
          <el-form :model="dialogcontentform" ref="directoryeditform">
            <div id="summernote"></div>
            <el-form-item>
              <el-button type="primary" @click="contentSubmit()"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-button type="primary" @click="bianji()" class="bianji"
          >编辑</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import request from "/utils/request.js";
import { ElMessage } from "element-plus";
import $ from "jquery";
export default {
  name: "index",
  data() {
    return {
      xianshi: true,
      total: null,
      pagination: {
        page: 1,
        pagesize: 10,
        project_id: "",
      },
      content: "",
      val: "",
      name: "",
      contentName: "文件修改记录",
      block: true,
      directory: [],
      directoryAddForm: false,
      directoryaddform: {
        pid: "",
        name: "",
        classify: "",
      },
      directoryEditForm: false,
      directoryeditform: {
        id: "",
        name: "",
      },
      dialogContentForm: false,
      dialogcontentform: {
        id: "",
        content: "",
      },

      titles: [
        {
          key: "plan",
          name: "研发计划书",
        },
        {
          key: "trial",
          name: "研发功能测试报告",
        },
        {
          key: "result",
          name: "阶段性成果报告",
        },
      ],

      recordData: [],
      recordAddForm: false,
      addform: {
        project_id: "",
        classify: "",
        draft_at: "",
        effective_at: "",
        content: "",
        version: "",
        draft: "",
        check: "",
        approve: "",
      },
      recordEditForm: false,
      recordeditform: {},
    };
  },
  components: {},
  created() {
    this.shuaxin(this.$route.query.name);
    this.name = this.$route.query.name;
  },
  mounted() {},
  watch: {
    $route(to, from) {
      this.shuaxin(this.$route.query.name);
      this.name = this.$route.query.name;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pagination.pagesize = val;
      this.Meting();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.Meting();
    },
    resetForm() {
      let _this = this;
      if (_this.$refs.addform !== undefined) _this.$refs.addform.resetFields();
      this.$data.addform = JSON.parse(
        JSON.stringify(this.$options.data().addform)
      );
    },
    add(item) {
      let _this = this;
      _this.directoryaddform = {};
      _this.directoryaddform.pid = item.id;
      _this.directoryaddform.classify = item.classify;
      _this.directoryAddForm = true;
    },
    addSubmit() {
      let _this = this;
      request.post("api/addreport", _this.directoryaddform).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          _this.$message.success("目录增加成功");
          _this.resetForm();
          _this.shuaxin(this.$route.query.name);
          _this.directoryAddForm = false;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    delet(item) {
      let _this = this;
      _this
        .$confirm("此操作将删除该目录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          request.post("api/delreport", { id: item.id }).then((res) => {
            if (res.code == 200) {
              _this.shuaxin(this.$route.query.name);
              _this.$message.success("删除目录成功！");
            } else {
              _this.$message.error("删除目录失败！");
            }
          });
        });
    },
    edit(item) {
      let _this = this;
      _this.directoryeditform.name = item.name;
      _this.directoryeditform.id = item.id;
      _this.directoryEditForm = true;
    },
    editSubmit() {
      let _this = this;
      request.post("api/modreport", _this.directoryeditform).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          _this.$message.success("目录修改成功");
          _this.shuaxin(this.$route.query.name);
          _this.directoryEditForm = false;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    // 点击目录内容切换
    contentTabs(index) {
      let _this = this;
      if (!index.children) {
        request.get("api/reportdetail", { id: index.id }).then((res) => {
          _this.block = false;
          _this.contentName = res.report.name;
          _this.dialogcontentform.id = res.report.id;
          _this.content = res.report.content;

          setTimeout(() => {
            this.summernoteTwo();
            $("#summernote2").summernote("code", _this.content);
          }, 10);
        });
      } else {
        ElMessage({
          message: "请点击该目录的子目录显示内容",
          type: "warning",
        });
      }
    },
    bianji() {
      let _this = this;
      _this.dialogContentForm = true;
      setTimeout(() => {
        _this.summernote();
        $("#summernote").summernote("code", _this.content);
      }, 10);
    },
    // 编辑内容
    contentSubmit() {
      let _this = this;
      _this.dialogcontentform.content = _this.content;
      request
        .post("api/modifyreportcontent", _this.dialogcontentform)
        .then((res) => {
          if (res.code == 200) {
            // 隐藏添加用户对话框
            this.$message.success("文档编辑成功");
            _this.dialogContentForm = false;
            $("#summernote2").summernote("code", _this.content);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 引用summernote
    summernote() {
      var that = this;
      $("#summernote").summernote({
        // 设置高度
        height: 300,
        // 设置语言
        lang: "zh-CN",
        // 禁止拖拽
        disableDragAndDrop: true,
        // 工具栏
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "italic", "underline", "strikethrough", "clear"]],
          ["color", ["color"]],
          ["para", ["paragraph"]],
          ["table", ["table"]],
          ["insert", ["picture"]],
          ["view", ["undo", "fullscreen", "codeview"]],
        ],
        // 将富文本编辑器中输入的内容保存到data数据中
        callbacks: {
          onChange: function (contents, $editable) {
            // contents: 输入的内容
            that.content = contents;
          },
          // 上传图片到服务器并在编辑器中显示出来
          onImageUpload: function (files) {
            // 上传图片到服务器，使用了formData对象，至于兼容性...据说对低版本IE不太友好
            var formData = new FormData();
            formData.append("img", files[0]);
            formData.append("original_filename", "");
            $.ajax({
              url: "https://sasstemplate.dyyseo.com/api/uploadfile", // 填写后台文件上传接口
              type: "post",
              data: formData,
              processData: false,
              contentType: false,
              success: function (data) {
                let a = JSON.parse(data);
                var image = `<p><img src="` + `${a.msg}` + `"></p>	`;
                $("#summernote").summernote("pasteHTML", image);
              },
            });
          },
        },
      });
    },
    summernoteTwo() {
      $("#summernote2").summernote({
        // 设置高度
        height: 300,
        // 设置语言
        lang: "zh-CN",
        // 禁止拖拽
        disableDragAndDrop: true,
        // 工具栏
        toolbar: [],
      });
      $("#summernote2").summernote("disable");
    },

    //
    record() {
      let _this = this;
      _this.block = true;
      _this.contentName = "文件修改记录";
    },
    recordSubmit() {
      let _this = this;
      _this.addform.project_id = _this.$route.query.id;
      _this.addform.classify = _this.$route.query.name;
      request.post("api/addfilelog", _this.addform).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          this.$message.success("记录添加成功");
          _this.recordAddForm = false;
          _this.resetForm();
          this.shuaxin(this.$route.query.name);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    recordDel(id) {
      let _this = this;
      _this
        .$confirm("此操作将删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          request.post("api/delfilelog", { id: id }).then((res) => {
            if (res.code == 200) {
              _this.$message.success("删除用户成功！");
              _this.shuaxin(_this.$route.query.name);
            } else {
              _this.$message.error(res.msg);
            }
          });
        });
    },
    recordEdit(id) {
      let _this = this;
      request.get("api/filelogdetail", { id: id }).then((res) => {
        _this.recordeditform = res.filelog;
        _this.recordEditForm = true;
      });
    },
    recordEditSubmit() {
      let _this = this;
      request.post("api/addfilelog", _this.recordeditform).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          this.$message.success("记录修改成功");
          _this.recordEditForm = false;
          this.shuaxin(this.$route.query.name);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    change(val) {
      // console.log(val); //以html格式获取simditor的正文内容
      this.content = val;
      val = this.val;
    },
    shuaxin(name) {
      let _this = this;
      request
        .get("api/reports", {
          project_id: _this.$route.query.id,
          field: name,
        })
        .then((res) => {
          _this.directory = res.data;
          if (this.name == "result") {
            this.xuran();
            this.xianshi = false;
          } else {
            this.record();
            this.xianshi = true;
          }
        });
      request
        .get("api/filelogs", {
          project_id: _this.$route.query.id,
          field: name,
        })
        .then((res) => {
          console.log(res);
          _this.recordData = res.filelogs.data;
          _this.total = res.filelogs.total;
        });
    },
    xuran() {
      var item = this.lopp(this.directory);
      this.contentTabs(item);
    },
    lopp(arr) {
      var result = null;
      if (arr != undefined) {
        for (let i = 0; i < arr.length; i++) {
          let a = arr[i];
          if (a.children == undefined) {
            result = a;
            break;
          } else {
            result = this.lopp(a.children);
            if (result != null) {
              break;
            }
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.report {
  display: flex;
  .el-directory {
    min-width: 300px;
    height: calc(100vh - 150px);
    border-radius: 4px;
    border: 1px solid #e6ebf5;
    background-color: #fff;
    overflow-y: auto;
    color: #303133;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    .title {
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center;
    }
    .modify_record {
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      padding-left: 10px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .modify_record:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 30px;
      background: #409eff;
    }
    .directory_one {
      list-style: none;
      > li {
        list-style: none;
        cursor: pointer;
        line-height: 25px;
        .directory_two {
          margin-left: 10px;
          > li {
            display: inline-block;
          }
          .directory_three {
            margin-left: 10px;
            > li {
              display: inline-block;
            }
            .directory_four {
              margin-left: 10px;
              > li {
                display: inline-block;
              }
            }
          }
        }
      }
      span {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  .el-content {
    padding: 0 20px;
    width: 80%;
    .title {
      text-align: left;
      display: block;
      .h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .content_title {
      position: relative;
      line-height: 30px;
      font-size: 20px;
      padding-left: 20px;
      display: block;
      height: 30px;
      margin-bottom: 20px;
    }
    .content_title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 4px;
      background: #409eff;
    }
    .content_text {
      margin-top: 30px;
    }
    .simditor {
      margin: 30px auto 0 auto;
    }
    .bianji {
      margin: 30px auto 0 auto;
      border: none;
      padding: 10px 40px;
      height: 50px;
      border-radius: 8px;
      line-height: 35px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      display: block;
    }
  }
}
.simditor .simditor-body {
  min-height: 150px !important;
}
.simditor {
  border: 1px solid #eeeeee !important;
}
</style>
>
