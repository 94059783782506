<!-- 模板 -->
<template>
  <div class="meeting">
    <el-button type="primary" @click="adds()" class="add" plain
      ><el-icon><Plus /></el-icon>新建会议
    </el-button>
    <el-table
      :data="meetingData"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="subject" label="会议主题" width="280" />
      <el-table-column prop="lanuch_date" label="时间" width="120" />
      <el-table-column prop="holder" label="主持" width="120" />
      <el-table-column prop="address" label="地点" width="200" />
      <el-table-column label="操作" min-width="200" fixed="right">
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click="delMeeting(scope.row.id)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="showMeeting(scope.row.id)"
            >修改</el-button
          >
          <el-button type="info" size="small" @click="veiwMeeting(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        background
        :current-page="page"
        :page-sizes="[5, 10, 15]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog v-model="dialogForm" title="新增会议" width="900px">
      <el-form :model="meetingform" ref="form" id="meeting_form">
        <table
          style="border-collapse: collapse; margin: 0 auto"
          border="1"
          align="center"
          width="600px"
        >
          <tr>
            <th colspan="5" style="font-size: 30px">第一页网络科技有限公司</th>
          </tr>
          <tr>
            <th colspan="5" style="font-size: 24px">会议记录表</th>
          </tr>
          <tbody align="center" style="font-size: 18px">
            <tr>
              <td width="15%">时间</td>
              <td width="20%">
                <textarea
                  class="regular"
                  v-model="meetingform.lanuch_date"
                ></textarea>
              </td>
              <td width="40%">地点</td>
              <td width="25%" colspan="2">
                <textarea
                  class="regular"
                  v-model="meetingform.address"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td width="15%">主持</td>
              <td width="20%" name="grade">
                <textarea
                  class="regular"
                  v-model="meetingform.holder"
                ></textarea>
              </td>
              <td width="40%">记录人</td>
              <td width="25%" colspan="2">
                <textarea
                  class="regular"
                  v-model="meetingform.recordor"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>会议主题</td>
              <td colspan="4" name="grade">
                <textarea
                  class="regular"
                  v-model="meetingform.subject"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>参加人员</td>
              <td colspan="4" name="grade">
                <textarea
                  class="regular"
                  v-model="meetingform.members"
                ></textarea>
              </td>
            </tr>
          </tbody>
          <tbody align="center" style="font-size: 18px">
            <tr>
              <td>NO.</td>
              <td>会议内容</td>
              <td>对策措施</td>
              <td>负责人</td>
              <td>备注</td>
            </tr>
            <tr v-for="(item, index) in meetingform.questions" :key="index">
              <td>{{ index + 1 }}</td>
              <td name="grade">
                <textarea
                  v-model="item.content"
                  @input="handleInput"
                ></textarea>
              </td>
              <td name="grade">
                <textarea
                  v-model="item.measure"
                  @input="handleInput"
                ></textarea>
              </td>
              <td width="12.5%" name="grade">
                <textarea
                  v-model="item.fuzeren"
                  @input="handleInput"
                ></textarea>
              </td>
              <td width="12.5%" name="grade">
                <textarea v-model="item.remark" @input="handleInput"></textarea>
              </td>
            </tr>
          </tbody>
          <tbody align="center" style="font-size: 18px">
            <tr style="text-align: center">
              <div class="tj" @click="tianjia1()">添加</div>
            </tr>
            <tr height="70px">
              <td>签到人员</td>
              <td name="grade" colspan="4">
                <textarea
                  v-model="meetingform.signup"
                  @input="handleInput"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="upload_title">附件上传:</div>
        <div class="form_imgs">
          <el-upload
            class="upload-demo"
            name="image"
            :multiple="true"
            action="https://sasstemplate.dyyseo.com/api/uploadfiles"
            :on-success="handleAddUpImage"
            :before-upload="beforeImageUpload"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.bmp"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleaddRemove"
            :on-exceed="handleExceed"
            :limit="fileListMax"
            :on-change="handleEditChange"
            :file-list="fileList"
          >
            <el-icon><Plus /></el-icon>
            <template #tip>
              <div class="el-upload_tip">
                只能上传5张图片,且单张图片大小不能超过5MB
              </div>
            </template>
          </el-upload>
        </div>
        <el-form-item class="form_button">
          <el-button type="primary" @click="onSubmit()">提交</el-button>
          <el-button @click="guanbi">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog v-model="dialogVisible" class="dialog_class">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>

    <el-dialog v-model="show_mettingForm" title="修改会议" width="900px">
      <template v-if="meeting_form != null">
        <el-form :model="meeting_form" id="meeting_form">
          <table
            style="border-collapse: collapse; margin: 0 auto"
            border="1"
            align="center"
            width="600px"
          >
            <tr>
              <th colspan="5" style="font-size: 30px">
                第一页网络科技有限公司
              </th>
            </tr>
            <tr>
              <th colspan="5" style="font-size: 24px">会议记录表</th>
            </tr>
            <tbody align="center" style="font-size: 18px">
              <tr>
                <td width="15%">时间</td>
                <td width="20%">
                  <textarea
                    class="regular"
                    v-model="meeting_form.lanuch_date"
                  ></textarea>
                </td>
                <td width="40%">地点</td>
                <td width="25%" colspan="2">
                  <textarea
                    class="regular"
                    v-model="meeting_form.address"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td width="15%">主持</td>
                <td width="20%" name="grade">
                  <textarea
                    class="regular"
                    v-model="meeting_form.holder"
                  ></textarea>
                </td>
                <td width="40%">记录人</td>
                <td width="25%" colspan="2">
                  <textarea
                    class="regular"
                    v-model="meeting_form.recordor"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>会议主题</td>
                <td colspan="4" name="grade">
                  <textarea
                    class="regular"
                    v-model="meeting_form.subject"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>参加人员</td>
                <td colspan="4" name="grade">
                  <textarea
                    class="regular"
                    v-model="meeting_form.members"
                  ></textarea>
                </td>
              </tr>
            </tbody>
            <tbody align="center" style="font-size: 18px">
              <tr>
                <td>NO.</td>
                <td>会议内容</td>
                <td>对策措施</td>
                <td>负责人</td>
                <td>备注</td>
              </tr>
              <tr v-for="(item, index) in meeting_form.questions" :key="index">
                <td>{{ index + 1 }}</td>
                <td name="grade">
                  <textarea
                    v-model="item.content"
                    @input="handleInput"
                    class="self-adaption"
                  ></textarea>
                </td>
                <td name="grade">
                  <textarea
                    v-model="item.measure"
                    @input="handleInput"
                    class="self-adaption"
                  ></textarea>
                </td>
                <td width="12.5%" name="grade">
                  <textarea
                    v-model="item.fuzeren"
                    @input="handleInput"
                    class="self-adaption"
                  ></textarea>
                </td>
                <td width="12.5%" name="grade">
                  <textarea
                    v-model="item.remark"
                    class="self-adaption"
                  ></textarea>
                </td>
              </tr>
            </tbody>

            <tbody align="center" style="font-size: 18px">
              <tr style="text-align: center">
                <div class="tj" @click="tianjia2()">添加+</div>
              </tr>
              <tr height="70px">
                <td>签到人员</td>
                <td name="grade" colspan="4">
                  <textarea
                    v-model="meeting_form.signup"
                    @input="handleInput"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="upload_title">附件上传:</div>
          <div class="form_imgs">
            <el-upload
              name="image"
              class="upload-demo"
              :multiple="true"
              action="https://sasstemplate.dyyseo.com/api/uploadfiles"
              :on-success="handleEditUpImage"
              :before-upload="beforeImageUpload"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.bmp"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleeditRemove"
              :on-exceed="handleExceed"
              :limit="fileListMax"
              :on-change="handleEditChange"
              :file-list="fileList"
            >
              <el-icon><Plus /></el-icon>
              <template #tip>
                <div class="el-upload_tip">
                  只能上传5张图片,且单张图片大小不能超过5MB
                </div>
              </template>
            </el-upload>
          </div>
          <el-form-item class="form_button">
            <el-button type="primary" @click="reviseSubmit()">修改</el-button>
            <el-button @click="show_mettingForm = false">取消</el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
    <el-dialog v-model="veiwForm" title="会议详情" width="900px">
      <el-form :model="veiw_Form" ref="form" id="meeting_form">
        <table
          style="border-collapse: collapse; margin: 0 auto"
          border="1"
          align="center"
          width="600px"
        >
          <tr>
            <th colspan="5" style="font-size: 30px">第一页网络科技有限公司</th>
          </tr>
          <tr>
            <th colspan="5" style="font-size: 24px">会议记录表</th>
          </tr>
          <tbody align="center" style="font-size: 18px">
            <tr>
              <td width="15%">时间</td>
              <td width="20%">
                <textarea
                  readonly
                  v-model="veiw_Form.lanuch_date"
                  class="regular"
                ></textarea>
              </td>
              <td width="40%">地点</td>
              <td width="25%" colspan="2">
                <textarea
                  class="regular"
                  readonly
                  v-model="veiw_Form.address"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td width="15%">主持</td>
              <td width="20%" name="grade">
                <textarea
                  class="regular"
                  readonly
                  v-model="veiw_Form.holder"
                ></textarea>
              </td>
              <td width="40%">记录人</td>
              <td width="25%" colspan="2">
                <textarea
                  class="regular"
                  readonly
                  v-model="veiw_Form.recordor"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>会议主题</td>
              <td colspan="4" name="grade">
                <textarea
                  class="regular"
                  readonly
                  v-model="veiw_Form.subject"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>参加人员</td>
              <td colspan="4" name="grade">
                <textarea
                  class="regular"
                  readonly
                  v-model="veiw_Form.members"
                ></textarea>
              </td>
            </tr>
          </tbody>
          <tbody align="center" style="font-size: 18px">
            <tr>
              <td>NO.</td>
              <td>会议内容</td>
              <td>对策措施</td>
              <td>负责人</td>
              <td>备注</td>
            </tr>
            <tr v-for="(item, index) in veiw_Form.questions" :key="index">
              <td>{{ index + 1 }}</td>
              <td name="grade">
                <textarea
                  readonly
                  v-model="item.content"
                  class="self-adaption"
                ></textarea>
              </td>
              <td name="grade">
                <textarea
                  readonly
                  v-model="item.measure"
                  class="self-adaption"
                ></textarea>
              </td>
              <td width="12.5%" name="grade">
                <textarea
                  readonly
                  v-model="item.fuzeren"
                  class="self-adaption"
                ></textarea>
              </td>
              <td width="12.5%" name="grade">
                <textarea
                  readonly
                  v-model="item.remark"
                  class="self-adaption"
                ></textarea>
              </td>
            </tr>
          </tbody>
          <tbody align="center" style="font-size: 18px">
            <tr height="70px">
              <td>签到人员</td>
              <td name="grade" colspan="4">
                <textarea
                  readonly
                  v-model="veiw_Form.signup"
                  @input="handleInput"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="upload_title">附件预览:</div>
        <div class="form_imgs">
          <div
            class="demo-image__preview"
            v-for="(url, index) in urls"
            :key="url"
          >
            <el-image
              style="width: 100px; height: 100px"
              :src="url"
              :preview-src-list="getSrcList(index)"
            />
          </div>
        </div>

        <el-form-item class="form_button">
          <el-button @click="veiwForm = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import request from "/utils/request.js";

export default {
  name: "",
  data() {
    return {
      total: null,
      pagination: {
        page: 1,
        pagesize: 10,
        project_id: "",
      },
      meetingData: [],
      add: [],
      dialogForm: false,
      show_mettingForm: false,
      veiwForm: false,
      meetingform: {
        holder: "",
        members: "",
        subject: "",
        recordor: "",
        address: "",
        lanuch_date: "",
        signup: "",
        questions: [],
        project_id: "",
        images: [],
      },
      meeting_form: null,
      veiw_Form: {},
      dialogImageUrl: "",
      dialogVisible: false,

      fileListMax: 5,
      fileList: [],
      urls: [],
    };
  },
  props: [],
  components: {},
  computed: {},
  filters: {},
  methods: {
    selfAdaption() {
      var count = 0;
      var a = setInterval(() => {
        count += 1;
        let ta = document.getElementsByClassName("self-adaption");
        if (ta.length > 0) {
          for (let i = 0; i < ta.length; i++) {
            ta[i].style.height = "auto";
            console.log(ta[i].style.height);
            ta[i].style.height = ta[i].scrollHeight + "px";
          }
          clearInterval(a);
        }
        if (count >= 10) {
          clearInterval(a);
        }
      }, 50);
    },
    handleInput(e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    },

    getSrcList(index) {
      return this.urls.slice(index).concat(this.urls.slice(0, index));
    },
    tianjia1() {
      let that = this;
      that.meetingform.questions.push({});
    },
    tianjia2() {
      let that = this;
      that.meeting_form.questions.push({});
    },
    reviseSubmit() {
      let that = this;
      request.post("api/modmeetting", that.meeting_form).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          that.show_mettingForm = false;
          that.$message.success("会议记录修改成功！");
          that.Meting();
        } else {
          that.$message.error("会议记录修改失败！");
        }
      });
    },
    guanbi() {
      let that = this;
      that.dialogForm = false;
      this.$router.go(0);
    },
    onSubmit() {
      let that = this;
      that.meetingform.project_id = that.$route.query.id;
      request.post("api/addmeetting", that.meetingform).then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          that.dialogForm = false;
          that.$message.success("会议记录新建成功！");
          that.Meting();
          that.guanbi();
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    delMeeting(id) {
      let that = this;
      that
        .$confirm("此操作将删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          request.post("api/delmeetting", { id: id }).then((res) => {
            if (res.code !== 200) {
              that.$message.error("删除用户失败！");
            }
            that.Meting();
            that.$message.success("删除用户成功！");
          });
        });
    },
    showMeeting(id) {
      let that = this;
      that.meeting_form = null;
      request.get("api/meettingdetail", { id: id }).then((res) => {
        that.fileList = [];
        that.meeting_form = res.meetting;
        var length = that.meeting_form.images.length;
        var i;
        for (i = 0; i <= length - 1; i++) {
          var imageUrl = that.meeting_form.images[i];
          var emptyObj = new Object();
          emptyObj.url = imageUrl;
          that.fileList.push(emptyObj);
        }
        this.selfAdaption();
        that.show_mettingForm = true;
      });
    },

    veiwMeeting(id) {
      let _this = this;

      request.get("api/meettingdetail", { id: id }).then((res) => {
        _this.veiw_Form = res.meetting;
        _this.urls = res.meetting.images;
        _this.srcList = _this.urls;
        this.selfAdaption();
        _this.veiwForm = true;
      });
    },
    Meting() {
      let that = this;
      that.pagination.project_id = that.$route.query.id;
      request.get("api/meettings", that.pagination).then((res) => {
        that.meetingData = res.meettings.data;
        this.total = res.meettings.total;
      });
    },
    handleSizeChange(val) {
      this.pagination.pagesize = val;
      this.Meting();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.Meting();
    },
    adds() {
      this.fileList = [];
      this.dialogForm = true;
    },
    handlePictureCardPreview(uploadFile) {
      let _this = this;
      _this.dialogImageUrl = uploadFile.url;
      _this.dialogVisible = true;
    },

    beforeImageUpload(file) {
      return this.xianzi(file);
    },

    handleEditChange(file, fileList) {
      let _this = this;
    },
    handleExceed(files, fileList) {
      if (files.length > this.fileListMax) {
        this.$message.error("最多上传五张图片");
      }
    },

    handleaddRemove(file, fileList) {
      let _this = this;
      _this.meetingform.images = [];
      for (let i = 0; i < fileList.length; i++) {
        _this.meetingform.images.push(fileList[i].url);
      }
    },
    handleeditRemove(file, fileList) {
      let _this = this;
      _this.meeting_form.images = [];
      for (let i = 0; i < fileList.length; i++) {
        _this.meeting_form.images.push(fileList[i].url);
      }
    },
    xianzi(file) {
      let _this = this;
      const isLimit = file.size / 1024 / 1024 <= 5;
      if (
        ["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(
          file.type
        ) === -1
      ) {
        _this.$message.error("上传图片只能是jpg/jpeg/gif/png格式");
        return false;
      }
      if (!isLimit) {
        _this.$message.error("上传图片大小不能超过" + 5 + "MB");
        return false;
      }
    },
    handleAddUpImage(response) {
      let _this = this;
      if (response.success) {
        _this.meetingform.images.push(response.files);
      }
    },
    handleEditUpImage(response) {
      let _this = this;
      if (response.success) {
        _this.meeting_form.images.push(response.files);
      }
    },
  },
  created() {
    this.Meting();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
// @import url(); 引入公共css类
.meeting {
  .add {
    margin-bottom: 20px;
  }
  .form_button {
    margin-top: 30px;
  }
  .tj {
    background: #409eff;
    padding: 5px 10px;
    border: none;
    margin: 10px;
    border-radius: 15px;
    width: 100px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  .demo-pagination-block {
    margin-top: 30px;
  }
  .upload_title {
    margin: 20px 0 20px 128px;
    font-size: 20px;
    line-height: 20px;
  }
  .form_imgs {
    width: 600px;
    margin: 0 auto;
    display: flex;

    .demo-image__preview {
      margin-right: 10px;
      border: 1px solid #dcdfe6;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
