<template>
  <div class="register">
    <img :src="bg" alt="" class="bg" />
    <div class="register_form">
      <div class="register_title">注册账号</div>
      <el-form ref="registerForm" :model="registerForm" :rules="rules">
        <el-form-item prop="realname">
          <el-input v-model="registerForm.realname" placeholder="请输入姓名">
            <template #prepend>
              <el-button icon="Stamp"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="username">
          <el-input v-model="registerForm.username" placeholder="请输入账号">
            <template #prepend>
              <el-button icon="User"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="registerForm.password"
          >
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="secret">
          <el-input placeholder="请输入秘钥" v-model="registerForm.secret">
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>

        <div class="login-btn">
          <el-button type="primary" @click="submitForm(registerForm)"
            >注册</el-button
          >
        </div>
        <div class="zhuce">
          <router-link :to="{ name: 'login' }">立即登录</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import Bg from "../assets/image/sign_bj.jpg";
import request from "/utils/request.js";
import { ElMessage } from "element-plus";
export default {
  name: "",
  data() {
    return {
      bg: Bg,
      registerForm: {
        realname: "",
        username: "",
        password: "",
        secret: "",
      },
      rules: {
        realname: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 4,
            message: "姓名长度在 2 到 4 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "用户名长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "密码长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        secret: [{ required: true, message: "请输入秘钥", trigger: "blur" }],
      },
      userToken: "",
    };
  },
  props: [],
  components: {},
  computed: {},
  filters: {},
  methods: {
    submitForm() {
      let _this = this;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          request.post("api/register", _this.registerForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
              _this.registerForm = {};
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.register {
  overflow: hidden;
  .bg {
    width: 100%;
    height: 943px;
  }

  .register_form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
    padding: 30px;
    text-align: center;
    .register_title {
      width: 100%;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      color: #fff;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
    }

    .el-button {
      height: 46px;

      position: relative;
    }
    .login-btn {
      button {
        width: 100%;
        height: 36px;
      }
    }
  }
  .zhuce {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>
